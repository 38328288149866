<template>
    <div class="right-side">
        <h2>{{$t("AccountPage.AccountNotifications.title")}}</h2>
        <div class="mb-4">{{$t("AccountPage.AccountNotifications.description")}}</div>
        
        <ul class="nav nav-pills mb-3">
            <li class="nav-item">
                <a @click.prevent="getByType()" class="nav-link" :class="{ active: notificationType === null }" id="home-tab" data-toggle="pill" href="#" aria-expanded="true">Все</a>
            </li>
            <li class="nav-item">
                <a @click.prevent="getByType(101)" class="nav-link" :class="{ active: notificationType === 101 }" id="profile-tab" data-toggle="pill" href="#" aria-expanded="false">Закупки</a>
            </li>
            <li class="nav-item">
                <a @click.prevent="getByType(102)" class="nav-link" :class="{ active: notificationType === 102 }" id="profile-tab" data-toggle="pill" href="#" aria-expanded="false">Новые планы</a>
            </li>
            <li class="nav-item">
                <a @click.prevent="getByType(0)" class="nav-link" :class="{ active: notificationType === 0 }" id="profile-tab" data-toggle="pill" href="#" aria-expanded="false">Системные</a>
            </li>   
        </ul>


        <div v-if="selectedNotification">
            <a href="#" class="float-right" v-if="selectedNotification" @click.prevent="unselectNotification"><i class="fas fa-window-close"></i></a>
            <h4>{{selectedNotification.subject}}</h4>
            <div>{{selectedNotification.createDate}}</div>
            <div v-html="selectedNotification && selectedNotification.body"></div>
        </div>
        <div v-else>
            <table-view :columns="myColumns" :data="notifications" :startWith="startWith" :selectItem="selectNotification">
                <template v-slot:head>
                    <tr><th>№</th><th>{{$t("notifications.theme")}}</th><th>{{$t("notifications.date")}}</th></tr>
                </template>
            </table-view>

            <beat-loader 
                v-if="beatLoad" 
                class="loader-center" 
                :color="'#553DB4'" 
                :loading="beatLoad" 
                :size="20" 
                :sizeUnit="'px'"
                />

            <paginate
                v-model="currentPage"
                :page-count="pageCount"
                :click-handler="changePage"
                :prev-text="'&laquo;'"
                :next-text="'&raquo;'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item'"
                :prev-link-class="'page-link'"
                :next-class="'page-item'"
                :next-link-class="'page-link'"
                >
            </paginate>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex'
    import TableView from "@/components/ui/TableViewNotification"

    export default {
        name: 'user-messages',
        components: {
            TableView
        },
        data() {
            return {
                beatLoad: false,
                myColumns: {
                    // column name : column label
                    'index': 'index', 
                    'subject': 'subject', 
                    'createDate': 'createDate'
                },
                notifications: [],
                notificationType: null,
                selectedNotification: null
            }
        },
        created() {
            this.onCreate();
        },
        mounted() {
            this.onShow();
        },
        methods: {
            onCreate() {
            },
            onShow() {
                if (this.$route.params.id) {
                    this.showNotification(this.$route.params.id);
                }
                if (this.$route.query.type) {
                    this.getByType(this.$route.query.type);
                }
                else {
                    this.getNotifications();
                }
            },
            showNotification(id) {
                this.$store.dispatch("LOAD_NOTIFICATION", id)
                .then((s) => {
                    if (s.id) {
                        this.selectNotification(s);
                    }
                });
            },
            getByType(type = null) {
                this.notificationType = type;
                this.changePage(1);
            },
            getNotifications() {
                this.beatLoad = true;
                this.$store.dispatch('LOAD_NOTIFICATIONS', this.notificationType)
                .then((data) => {
                    this.beatLoad = false;
                    this.notifications = data.data.content;
                })
                ;
            },
            changePage(page) {
                this.currentPage = page;
                this.getNotifications();
            },
            selectNotification(item) {
                if (item && item.id) {

                    this.$store.dispatch("LOAD_NOTIFICATION", item.id)
                    .then((s) => {
                        if (s.id) {
                            this.selectedNotification = s;
                            this.markReaded(s.id);
                        }
                    });
                }
            },
            unselectNotification() {
                this.selectedNotification = null;
            },
            markReaded(id) {
                this.$store.dispatch("SAVE_NOTIFICATION", { id: id, isRead: 1})
                    .then(() => this.getNotifications())
                    ;
            }
        },
        computed: {
            ...mapGetters([
                'NOTIFICATION', 
                'NOTIFICATIONS', 
                'NOTIFICATIONS_CURRENT_PAGE', 
                'NOTIFICATIONS_PAGE_COUNT',
                'NOTIFICATIONS_STARTWITH',
                'NOTIFICATIONS_PAYLOAD',
            ]),
            currentPage: {
                get() {
                    return this.NOTIFICATIONS_CURRENT_PAGE;
                },
                set(newPage) {
                    this.$store.commit('SET_NOTIFICATIONS_CURRENT_PAGE', newPage);
                }
            },
            pageCount: {
                get() {
                    return this.NOTIFICATIONS_PAGE_COUNT;
                },
                set(newValue) {
                    console.log("UserMessages.vue: You can't change page count to " + newValue);
                }
            },
            notification() {
                return this.NOTIFICATION;
            },
            startWith() {
                return this.NOTIFICATIONS_STARTWITH;
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>